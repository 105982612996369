import React from 'react';
import './oneLineComponent.scss';
import leaf from '../../images/leaf.svg';

const OneLineComponent = (props) => {

    const { navigateToDefault } = props;

    return (
        <div className="one-line-converter">
            <div className="one-line-background">
                <img alt="Wilderness International" src={leaf} />
            </div>
            <div className="one-line-container">
                <div className="icon-arrow-down">
                    <span onClick={() => navigateToDefault(0)} ><i className="pi pi-chevron-up"></i></span>
                </div>
            </div>
        </div>
    );
}

export default OneLineComponent;
