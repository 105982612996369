import React, { useState, useRef, useLayoutEffect } from 'react';
import './converter.scss';
import { LanguageList, RECURRING_LIST, RECURRING_OPTION, Status_Region } from '../../utils/Utils';
import DonationForm from '../donationForm';
import IConverterParameters from './models/ConverterParameters';
import ConverterCommon from '../../common/converterCommon';
import AnimateHeight from 'react-animate-height';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import OneLineComponent from '../../common/oneLineComponent/oneLineComponent';

const Converter = props => {
  const { t, i18n, region, campaign, rhythm, currency, setCurrency, regions, setRegion, goal } = props;

  const parameters = props.parameters as IConverterParameters;
  const donationRef = useRef(null);
  const [isStepDis, setIsStepDis] = useState(false);
  const [[page, direction], setPage] = useState([0, 0]);
  const [disableScrolll, setDisableScrolll] = useState(false);
  const [language, setLanguage] = useState<any>(() => {
    const lang = LanguageList.find(l => l.code === parameters.getLanguage());
    return lang;
  });
  var timeoutAnimation: any = null;
  const [calcValues, setCalcValues] = useState({
    value: 0,
    amount: 0,
    area: 0,
    co2: 0,
  });
  const [rhythmData, setRhythmData] = useState<any>();
  const [isRhythmNo, setIsRhythmNo] = useState(false);

  useLayoutEffect(() => {
    if (rhythm === RECURRING_OPTION.NO) {
      setRhythmData(RECURRING_OPTION.NO);
    } else {
      const rhythmValue = rhythm
        ? RECURRING_LIST[language.code].find(item => item.code === rhythm)?.code ||
          RECURRING_LIST[language.code][0]?.code
        : RECURRING_LIST[language.code][0]?.code;
      setRhythmData(rhythmValue);
    }
  }, [rhythm]);

  useDidMountEffect(() => {
    if (!isStepDis && !disableScrolll) {
      clearTimeout(timeoutAnimation);
      if (donationRef && donationRef.current) {
        timeoutAnimation = setTimeout(() => {
          // @ts-ignore: Object is possibly 'null'.
          donationRef.current.scrollIntoView();
        }, 600);
      }
    }
    setDisableScrolll(false);
  }, [isStepDis]);

  const navigateToDefault = index => {
    setIsStepDis(index === 1);
    setPage([index, index - page]);
    setDisableScrolll(true);
  };

  return (
    <div>
      {t('title') !== 'title' ? (
        region.status === Status_Region.Completed || (campaign && campaign.status === Status_Region.Completed) ? (
          <div className="completed-land-container">
            <div className="completed-land-wrapper row">
              <h1>{t(`Hi_Text`)}</h1>
              <h1 className="w-dark-yellow-text">
                {region.status === Status_Region.Completed ? t('Region_Completed') : t('Campaign_Completed')}
              </h1>
            </div>
          </div>
        ) : (
          <div>
            <div className="donation">
              <AnimateHeight duration={600} height={!isStepDis ? `auto` : 0}>
                <ConverterCommon
                  t={t}
                  i18n={i18n}
                  region={region}
                  campaign={campaign}
                  parameters={parameters}
                  language={language}
                  setLanguage={setLanguage}
                  calcValues={calcValues}
                  setCalcValues={setCalcValues}
                  currency={currency}
                  setCurrency={setCurrency}
                  regions={regions}
                  setRegion={setRegion}
                  donationRef={donationRef}
                  rhythmData={rhythmData}
                  setRhythmData={setRhythmData}
                  page={page}
                  isRhythmNo={isRhythmNo}
                />
              </AnimateHeight>
              {isStepDis && <OneLineComponent navigateToDefault={navigateToDefault} />}
              <DonationForm
                language={language}
                calcValues={calcValues}
                currency={currency}
                t={t}
                region={region}
                campaign={campaign}
                isStepDis={isStepDis}
                setIsStepDis={setIsStepDis}
                page={page}
                direction={direction}
                setPage={setPage}
                donationRef={donationRef}
                goal={goal}
                parameters={parameters}
                rhythmData={rhythmData}
                setIsRhythmNo={setIsRhythmNo}
              />
            </div>
          </div>
        )
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Converter;
