import * as React from 'react';
import Converter from '../components/converter/converter';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ConverterParameters from '../components/converter/models/ConverterParameters';
import DonationFormAPI from '../services/donationFormAPI';
import { useEffect, useState } from 'react';
import LoadingComponent from '../common/Loading';
import {
  isBrowserLocal,
  navigateUrl,
  URL,
  AreaList,
  CurrencyList,
  Status_Region,
  CHANGE_CODE_CAMPAIGN,
} from '../utils/Utils';
import { withTranslation } from 'react-i18next';
import { filterRegions } from '../utils/funtionCommon';

const IndexPage = props => {
  const { location, t, i18n } = props;
  const parameters = new ConverterParameters(location.search);
  const queryParams = new URLSearchParams(location?.search);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState(null);
  const [campaign, setCampaign] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const emptyPage = (
    <Layout>
      <Seo title="Donate" />
      <div></div>
    </Layout>
  );
  const rhythm = parameters.getRhythm();
  const goal = parameters.getGoal();
  const [area, setArea] = useState(AreaList[0]);
  const [currency, setCurrency] = useState(() => {
    return CurrencyList.find(c => c.code === parameters.getCurrency());
  });

  if (!isBrowserLocal()) {
    return emptyPage;
  }

  const getRegionByCode = async () => {
    try {
      setLoading(true);
      const code = parameters.getRegionCode() || parameters.getLandCode();
      const { data: selectedRegion } = await DonationFormAPI.getRegionByCode(code);

      if (selectedRegion.status !== Status_Region.Active) {
        navigateUrl(`/${URL.NOT_FOUND}`, 'Region_Completed');
        return emptyPage;
      }

      if (!selectedRegion) {
        navigateUrl(`/${URL.NOT_FOUND}`);
        return emptyPage;
      } else {
        if (parameters.getLandCode() && !parameters.getRegionCode()) {
          if (queryParams.has('area')) {
            const regionValue: any = queryParams.get('area');
            queryParams.delete('area');
            queryParams.set('region', regionValue);
            const newURL = `${location.origin}${location.pathname}?${queryParams.toString()}`;
            history.replaceState(null, '', newURL);
          }
        }
      }

      parameters.setRegion(selectedRegion);
      setRegion(selectedRegion);
      setRegions(filterRegions(selectedRegion.regions) || []);
      setLoading(false);
    } catch (error) {
      navigateUrl(`/${URL.NOT_FOUND}`, error?.response?.status === 404 ? 'Region_NotFound' : '');
      return emptyPage;
    }
  };

  const getCampaignByCode = async () => {
    try {
      setLoading(true);
      const { data: selectedCampaign } = await DonationFormAPI.getCampaignByCode(parameters.getCampaignCode());

      if (!selectedCampaign) {
        navigateUrl(`/${URL.NOT_FOUND}`);
        return emptyPage;
      }
      if (selectedCampaign.is_deleted || !['active', 'completed', 'public'].includes(selectedCampaign.status)) {
        navigateUrl(`/${URL.NOT_FOUND}`, 'Campaign_Completed');
        return emptyPage;
      }

      setCampaign(selectedCampaign);
      setRegion(selectedCampaign.region);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        navigateUrl(`/${URL.NOT_FOUND}`, 'Campaign_NotFound');
        return emptyPage;
      }
    }
  };

  const onAreaChange = e => {
    setArea(e.value);
  };

  const onCurrenyChange = e => {
    setCurrency(e.value);
    localStorage.setItem('currency_code', e.value.code);
  };

  useEffect(() => {
    if (!parameters.getRegionCode() && !parameters.getCampaignCode() && !parameters.getLandCode()) {
      navigateUrl(`/?region=secret-forest`);
      parameters.setRegionCode('secret-forest');
    }

    if (parameters.getCampaignCode()) {
      const campaign: any = CHANGE_CODE_CAMPAIGN.find(c => c.name === parameters.getCampaignCode()?.toLowerCase());
      if (campaign && campaign?.code) {
        navigateUrl(`/?campaign=${campaign.url}`);
        parameters.setCampaignCode(campaign.code);
      }

      getCampaignByCode();
    } else {
      getRegionByCode();
    }
  }, []);

  return (
    <Layout
      campaign={campaign || null}
      area={area}
      currency={currency}
      onCurrenyChange={onCurrenyChange}
      onAreaChange={onAreaChange}
      isShowAction={true}
      minHeight="100vh"
      location={location}
      style={{ background: '#F7F7F7' }}
    >
      <Seo title="Donate" />
      {loading && !region && !campaign ? (
        <LoadingComponent />
      ) : (
        <Converter
          t={t}
          i18n={i18n}
          parameters={parameters}
          region={campaign ? campaign?.region : region}
          campaign={campaign}
          rhythm={rhythm}
          currency={currency}
          setCurrency={setCurrency}
          regions={regions}
          setRegion={setRegion}
          goal={goal}
        ></Converter>
      )}
    </Layout>
  );
};

export default withTranslation('blog')(IndexPage);
